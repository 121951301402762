@import '../../common.scss';

.root {
  position: absolute;
  top: 25px;
  bottom: 25px;
  left: 25px;
  right: 25px;
  
  @include flex-column;
  overflow: hidden;

  padding: 10px;

  background-color: rgba(14, 10, 10, 0.9);

  transition: opacity ease 0.5s;
  opacity: 1;

  &:not(.open) {
    opacity: 0;
    pointer-events: none;
  }

  .header {
    @include flex-row;
    align-items: flex-start;

    .title {
      flex: 1 1 0;

      color: white;
      padding: 25px;
      margin: 0;
      font-size: 20pt;
    }

    .closeButton {
      @include button;
      padding-left: 2em;
      padding-right: 2em;
      
      &:hover {
        background-color: white;
        color: black;
      }
    }
  }

  .body {
    flex: 1 1 0;
    position: relative;

    .mainContent {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      @media screen and (orientation: portrait) {
        @include flex-column;
        flex-direction: column-reverse;
      }
      @media screen and (orientation: landscape) {
        @include flex-row;
      }

      .imageSelector {
        @media screen and (orientation: portrait) {
          @include flex-row;
          overflow-x: scroll;
        }
        @media screen and (orientation: landscape) {
          @include flex-column;
          overflow-y: scroll;
        }
        padding: 25px;
        gap: 25px;
      }
      .selectedImage {
        flex: 1 1 0;
        object-fit: contain;
        margin: 25px;
        overflow: hidden;
      }
    }
  }
}