@import '../../common.scss';

$standardGap: 3vw;
$backgroundColor: #a05310;
$padding: 15px;

.root {
  @include flex-column;
  gap: 3vh;

  padding-top: $padding;
  padding-left: $padding;
  padding-right: $padding;

  box-sizing: border-box;

  justify-content: space-around;
  min-height: 100vh;

  background-color: $backgroundColor;

  @media screen and (max-width: $mobileBreakPoint) {
    min-height: unset;
  }

  .topSection {
    @include flex-row;

    max-height: 70vh;
    gap: $standardGap;
    
    @media screen and (max-width: $mobileBreakPoint) {
      @include flex-column;
      align-items: center;
      max-height: unset;
    }

    .titleSection {
      @include flex-column;

      .spacer {
        flex: 1 1 0;
      }
    }

    .shopImageWrapper {
      flex: 1 1 0;

      .shopImage {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  .bottomSection {
    overflow: hidden;
  }
}