.root {
  position: relative;

  &.hasOnClick {
    cursor: pointer;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;

    transition: opacity ease 0.5s;
    opacity: 0;
    pointer-events: none;

    &.loading{
      opacity: 1;
      pointer-events: unset;
    }
  }
}