@import '../../common.scss';

$titleAreaBackgroundColor: #FFF822;

$width: 540px;
$height: 337px;

.root {
  @include flex-row;

  background-color: $titleAreaBackgroundColor;
  background-position: center center;
  background-size: cover;

  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;

  padding-left: 1em;

  width: $width;
  height: $height;

  @media screen and (max-width: $width) {
    width: 100%;
    height: unset;
    @include flex-column;
    padding-left: unset;
  }

  h1 {
    display: block;
    user-select: none;

    margin: 0;
    
    color: $titleColor;
    text-decoration: underline;

    font-size: 25pt;
    font-family: 'Lucida Calligraphy', 'Perpetua', 'Georgia', 'Times New Roman';

    max-width: 260px;

    span { 
      display: block;

      &::first-letter {
        font-size: 170%;
      }
      &:nth-child(2) {
        padding-left: 1em;
      }
      &:nth-child(3) {
        padding-left: 2em;
      }
    }
  }
  
  .logo {
    margin-top: 5px;
    margin-right: -20px;
    max-height: 12em;
    align-self: flex-start;
  
    @media screen and (max-width: $width) {
      margin-top: unset;
      margin-right: unset;
      align-self: unset;
    }
  }
}