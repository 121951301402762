@import '../../common.scss';

@mixin scroll-button {
  @include button;

  background-color: transparent;
  opacity: 0;
  transition: 
    opacity $buttonTransitionSpeed ease,
    // the below two transitions need to be re-defined from @mixin button
    background-color $buttonTransitionSpeed ease,
    color $buttonTransitionSpeed ease;

  &.visible {
    opacity: 100%;
  }

  border: solid 1px white;

  position: absolute;
  top: 0px;
  bottom: 20px;

  svg {
    width: 20px;
  }
}

.root {
  @include flex-row;
  position: relative;

  .scrollPane {
    @include flex-row;
    flex: 1 1 0;
    overflow-x: scroll;
  }
  
  .scrollLeftButton {
    @include scroll-button;
    left: 0;
  }

  .scrollRightButton {
    @include scroll-button;
    right: 0;
  }
}