@import '../../common.scss';

.root {
  @include flex-row;
  justify-content: center;

  &.alternateBackgroundColor {
    background-color: $white;
  }
  
  .wrapper {
    margin: 1rem;
    width: 840px;
    max-width: 100vw;
  }
}