$imageHeight: 120px;
$imageWidth: 208px;

.root {
  position: relative;
  width: $imageWidth;
  height: $imageHeight;

  &.selected {
    border: solid white 2px;
  }

  .image {
    object-fit: cover;

    width: $imageWidth;
    height: $imageHeight;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    pointer-events: none;
    transition: background-color ease 0.5s;
  }

  &:hover .overlay {
    background-color: rgba(255, 255, 255, 0.2);
  }
}