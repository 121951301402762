@import '../../common.scss';

$imageHeight: 120px;
$imageWidth: 208px;
$titleLineHeight: 20px;

.root {
  @include flex-column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;

  height: calc($imageHeight + 2 * $titleLineHeight);

  transition: background-color 0.5s ease;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.137);
  }

  .imageWrapper {
    flex: 1 1 0;
    height: $imageHeight;
    width: $imageWidth;

    .image {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .jobTitle {
    min-height: calc(2 * $titleLineHeight);
    text-align: center;
    line-height: $titleLineHeight;
    color: $white;
  }
}