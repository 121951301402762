@import '../../common.scss';

.root {
  @include flex-row;
  justify-content: stretch;
  align-items: center;
  flex-wrap: wrap;

  position: sticky;
  top: 0;

  justify-content: space-around;

  .homeLink {
    @include flex-row;
    align-items: center;
    text-decoration: none;

    .logo {
      max-height: 60px;
    }

    .title {
      color: $titleColor;
      text-decoration: underline;
      font-size: 14pt;
      margin-top: 0;
      margin-bottom: 0;
      font-family: 'Lucida Calligraphy', 'Perpetua', 'Georgia', 'Times New Roman';

      span {
        display: block;
      }

      :nth-child(2) {
        padding-left: 1em;
      }
      :nth-child(3) {
        padding-left: 2em;
      }
    }
  }


  &:not(.inline) {
    background-color: $bannerColor;

    .navLinks {
      @include flex-row;
      align-items: center;
      justify-content: space-around;

      .navLink {
        display: inline-block;

        @include button;
      }
    }
  }

  &.inline {
    position: static;
    top: unset;

    .navLinks {
      @include flex-row;
      flex: 1 1 0;

      align-items: center;
      justify-content: space-around;

      .navLink {
        display: inline-block;

        @include button;

        padding: 1.0em 0.5em;
        
        font-size: 22px;
        text-decoration: underline;

        background-color: transparent;
        color: $white;

        &:hover {
          color: #a05310;
          background-color: $white;
        }
      }
    }
  }
}