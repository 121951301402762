@import './common.scss';

html, body, #root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  font-family: 'Lucida Bright', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14pt;
}

#root {
  @include flex-column;
  overflow-y: scroll;
  overflow-x: hidden;
}

h1, h2, h3 {
  color: $headingColor;
}

.copyright-notice {
  position: relative;
  bottom: 0;
  right: 0;
}