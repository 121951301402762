@import '../../common.scss';

.linksContainer {
  @include flex-row;

  align-items: stretch;
  justify-content: space-around; 
    
  flex-wrap: wrap;

  margin-top: 2rem;
  margin-bottom: 2rem;

  .link {
    @include flex-column;
    align-items: center;
    justify-content: space-between;

    color: inherit;
    text-decoration: none;
    font-size: 20px;

    padding: 20px 40px;
    margin: 0 10px;

    transition: ease 0.2s background-color;
    
    &:hover {
      background-color: $white;
    }

    svg {
      color: darken($bannerColor, 20%);
      height: 10rem;
      width: 10rem;
    }
    
    .text {
      max-width: 15rem;
      margin-top: 10px;
      text-align: center;
      
      font-style: italic;
    }
  }
}