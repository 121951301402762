@font-face {
  font-family: 'Lucida Calligraphy';
  src: url('res/fonts/LucidaUnicodeCalligraphy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lucida Calligraphy';
  src: url('res/fonts/LucidaUnicodeCalligraphyBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lucida Bright';
  src: url('res/fonts/LucidaBrightRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lucida Bright';
  src: url('res/fonts/LucidaBrightBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}