@import '../../common.scss';

.root {
  flex: 1 1 0;

  @include flex-column;

  .contentContainer {
    flex: 1 1 0;

    margin: 20px;

    @include flex-column;
    align-items: center;

    text-align: center;

    .logo {
      margin: 2em;
      max-width: 100%;
    }

    .frontPageButton {
      display: block;

      @include button;
    }
  }
}