
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

$bannerColor: #F7B32B;
$headingColor: #2D1E2F;
$white: #FCF6B1;
$buttonColor: #F7B32B;
$buttonHoverColor: #2D1E2F;

$titleColor: black;//rgb(226, 13, 13);

$buttonTransitionSpeed: 0.3s;

$mobileBreakPoint: 1000px;

@mixin button {
  display: inline-block;
  cursor: pointer;

  border: none;

  background-color: $buttonColor;

  padding: 1em;

  text-align: center;
  text-decoration: none;
  color: $headingColor; 

  transition: 
    background-color $buttonTransitionSpeed ease,
    color $buttonTransitionSpeed ease;

  &:hover {
    color: $white;
    background-color: $buttonHoverColor;
  }
}
